export type TranslationsFields = {
  newMessageToast: string;
  defaultError: string;
  userDataSuccess: string;
  newMessageBtn: string;
  you: string;
  sendPlaceholder: string;
  learnMore: string;
  resolutionQuestion: string;
  helped: string;
  notHelped: string;
  collectUserDataLabel: string;
  emailPlaceholder: string;
  phonePlaceholder: string;
  fullNamePlaceholder: string;
  dummyAiMessage: string;
  dummyUserMessage: string;
  supportRequestedTitle: string;
  supportRequestedDescription: string;
  joinText: string;
  aiIsWriting?: string;
  speaking: string;
};
const DEFAULT_ENGLISH: TranslationsFields = {
  newMessageToast: 'New message!',
  defaultError: 'Something went wrong',
  userDataSuccess: 'User data successfully saved',
  newMessageBtn: 'New message',
  you: 'You',
  sendPlaceholder: 'Send message',
  learnMore: 'Learn More',
  resolutionQuestion: 'This response helpful to you?',
  helped: 'That helped 👍',
  notHelped: "That didn't help 👎",
  collectUserDataLabel: 'Fill out the form to get updates.',
  emailPlaceholder: 'Email',
  phonePlaceholder: 'Phone',
  fullNamePlaceholder: 'Full Name',
  dummyAiMessage: 'Dummy AI message',
  dummyUserMessage: 'Dummy user message',
  supportRequestedTitle: 'Human Support Requested 🎉',
  supportRequestedDescription:
    'We will send your request to all available agents, you will receive answers as soon as possible from here.',
  joinText: 'is joined the conversation',
  aiIsWriting: 'AI is writing',
  speaking: 'Speaking',
};

const DEFAULT_SPANISH: TranslationsFields = {
  newMessageToast: 'Nuevo mensaje!',
  defaultError: 'Algo salió mal',
  userDataSuccess: 'Datos de usuario guardados correctamente',
  newMessageBtn: 'Nuevo mensaje',
  you: 'Usted',
  sendPlaceholder: 'Enviar mensaje',
  learnMore: 'Más información',
  resolutionQuestion: 'Le ha sido útil esta respuesta?',
  helped: 'Eso ayudó 👍',
  notHelped: 'Eso no ayudó 👎',
  collectUserDataLabel: 'Rellene el formulario para recibir actualizaciones.',
  emailPlaceholder: 'Correo electrónico',
  phonePlaceholder: 'Teléfono',
  fullNamePlaceholder: 'Nombre completo',
  dummyAiMessage: 'Mensaje AI ficticio',
  dummyUserMessage: 'Mensaje de usuario ficticio',
  supportRequestedTitle: 'Soporte humano solicitado 🎉',
  supportRequestedDescription:
    'Enviaremos su solicitud a todos los agentes disponibles, recibirá respuestas lo antes posible desde aquí.',
  joinText: 'se unió a la conversación',
  aiIsWriting: 'AI está escribiendo',
  speaking: 'Hablando',
};

const DEFAULT_GERMAN: TranslationsFields = {
  newMessageToast: 'Neue Nachricht!',
  defaultError: 'Etwas ist schief gelaufen',
  userDataSuccess: 'Daten des Benutzers erfolgreich gespeichert',
  newMessageBtn: 'Neue Nachricht',
  you: 'Sie',
  sendPlaceholder: 'Nachricht senden',
  learnMore: 'Mehr erfahren',
  resolutionQuestion: 'Ist diese Antwort hilfreich für Sie?',
  helped: 'Das hat geholfen 👍',
  notHelped: 'Das hat nicht geholfen 👎',
  collectUserDataLabel: 'Füllen Sie das Formular aus, um Updates zu erhalten.',
  emailPlaceholder: 'E-Mail',
  phonePlaceholder: 'Telefon',
  fullNamePlaceholder: 'Vollständiger Name',
  dummyAiMessage: 'Dummy AI-Nachricht',
  dummyUserMessage: 'Dummy-Benutzernachricht',
  supportRequestedTitle: 'Menschliche Unterstützung angefordert 🎉',
  supportRequestedDescription:
    'Wir werden Ihre Anfrage an alle verfügbaren Agenten senden, Sie erhalten so schnell wie möglich Antworten von hier.',
  joinText: 'hat sich dem Gespräch angeschlossen',
  aiIsWriting: 'AI schreibt',
  speaking: 'Sprechen',
};

const DEFAULT_TURKISH: TranslationsFields = {
  newMessageToast: 'Yeni Mesaj!',
  defaultError: 'Bir şeyler ters gitti.',
  userDataSuccess: 'Kullanıcı verileri başarıyla kaydedildi.',
  newMessageBtn: 'Yeni Mesaj',
  you: 'Sen',
  sendPlaceholder: 'Mesaj Gönder',
  learnMore: 'Daha fazla bilgi',
  resolutionQuestion: 'Bu yanıt size yardımcı oldu mu?',
  helped: 'Yanıt yardımcı oldu 👍',
  notHelped: 'Yanıt yardımcı olmadı 👎',
  collectUserDataLabel: 'Güncellemeleri almak için formu doldurun.',
  emailPlaceholder: 'E-posta',
  phonePlaceholder: 'Telefon',
  fullNamePlaceholder: 'Tam Adınız',
  dummyAiMessage: 'Demo yapay zeka mesajı',
  dummyUserMessage: 'Demo kullanıcı mesajı',
  supportRequestedTitle: 'Destek Talep Edildi 🎉',
  supportRequestedDescription:
    'Talebinizi tüm mevcut temsilcilere göndereceğiz, buradan en kısa sürede cevaplar alacaksınız.',
  joinText: 'sohbete katıldı',
  aiIsWriting: 'AI yazıyor',
  speaking: 'Konuşuyor',
};

export { DEFAULT_ENGLISH, DEFAULT_SPANISH, DEFAULT_GERMAN, DEFAULT_TURKISH };
