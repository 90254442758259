import { Prisma } from '@prisma/client';
import { NextRequest, NextResponse } from 'next/server';
import { z } from 'zod';

export const ErrorCode = z.enum([
  'bad_request',
  'not_found',
  'internal_server_error',
  'unauthorized',
  'forbidden',
  'exceeded_limit',
  'rate_limit_exceeded',
  'too_long',
  'conflict',
  'invalid_id',
  'invalid_input_data',
]);

export const errorCodeToHttpStatus: Record<
  z.infer<typeof ErrorCode>,
  number
> = {
  bad_request: 400,
  unauthorized: 401,
  forbidden: 403,
  exceeded_limit: 406,
  not_found: 404,
  conflict: 409,
  rate_limit_exceeded: 429,
  internal_server_error: 500,
  too_long: 413,
  invalid_id: 422,
  invalid_input_data: 422,
};

export type ErrorCodes = z.infer<typeof ErrorCode>;
export type ErrorResponse = {
  error: {
    code: ErrorCodes;
    message: string;
    isLimitReached?: boolean;
  };
  status: number;
};

export class CustomApiError extends Error {
  public readonly code: ErrorCodes;
  public readonly isLimitReached: boolean;

  constructor({
    code,
    message,
    isLimitReached,
  }: {
    code: ErrorCodes;
    message: string;
    isLimitReached?: boolean;
  }) {
    super(message);
    this.code = code;
    this.isLimitReached = isLimitReached;
  }
}

function handlePrismaErrorMessage(
  err: Prisma.PrismaClientKnownRequestError,
): ErrorResponse {
  switch (err.code) {
    case 'P2000':
      //"The provided value for the column is too long for the column's type. Column: {column_name}"
      return {
        error: {
          message: `The provided value is too long for the column's type.`,
          code: 'too_long',
        },
        status: errorCodeToHttpStatus['too_long'],
      };
    case 'P2001':
      //"The record searched for in the where condition ({model_name}.{argument_name} = {argument_value}) does not exist"
      return {
        error: {
          message: `The record does not exist.`,
          code: 'not_found',
        },
        status: errorCodeToHttpStatus['not_found'],
      };
    case 'P2002':
      // handling duplicate key errors
      return {
        error: {
          message: `Duplicate field value: ${err?.meta?.target}`,
          code: 'conflict',
        },
        status: errorCodeToHttpStatus['conflict'],
      };
    case 'P2014':
      // handling invalid id errors
      return {
        error: {
          message: `Invalid ID: ${err?.meta?.target}`,
          code: 'invalid_id',
        },
        status: errorCodeToHttpStatus['invalid_id'],
      };
    case 'P2003':
      // handling invalid data errors
      return {
        error: {
          message: `Invalid input data: ${err?.meta?.target}`,
          code: 'invalid_input_data',
        },
        status: errorCodeToHttpStatus['invalid_input_data'],
      };
    case 'P2025':
      //An operation failed because it depends on one or more records that were required but not found. {cause}
      return {
        error: {
          message: `One or more required records were not found`,
          code: 'not_found',
        },
        status: errorCodeToHttpStatus['not_found'],
      };
    default:
      // handling all other errors
      return {
        error: {
          message: `Something went wrong. You can try again or contact our Support Team for help.`,
          code: 'internal_server_error',
        },
        status: errorCodeToHttpStatus['internal_server_error'],
      };
  }
}

function handleApiError(error: any): ErrorResponse & { status: number } {
  console.error('API error occurred', error.message);

  // CustomApiError errors
  if (error instanceof CustomApiError) {
    return {
      error: {
        code: error.code,
        message: error.message,
        isLimitReached: error.isLimitReached,
      },
      status: errorCodeToHttpStatus[error.code],
    };
  }

  // Prisma errors
  if (error instanceof Prisma.PrismaClientKnownRequestError) {
    return handlePrismaErrorMessage(error);
  }

  // Fallback
  // Unhandled errors are not user-facing, so we don't expose the actual error
  const errorMessage = error?.message;
  return {
    error: {
      code: 'internal_server_error',
      message:
        errorMessage ??
        'An internal server error occurred. Please contact our support if the problem persists.',
    },
    status: 500,
  };
}

function createRequestLogMessage(
  request: NextRequest,
  error: ErrorResponse['error'],
) {
  try {
    const { url, method } = request;

    const apiEndpoint = url?.split('api/')?.[1] ?? 'unknown';
    const message = `[${method}] /api/${apiEndpoint}`;

    return {
      message,
      properties: {
        code: error.code,
        message: error.message,
      },
    };
  } catch {
    return {
      message: 'Unknown API endpoint',
      properties: {
        code: 'unknown',
        message: `Unknown API endpoint error. URL: ${request?.url}, Method: ${request?.method}`,
      },
    };
  }
}

export function handleAndReturnAPIErrorResponse({
  request,
  err,
  other,
}: {
  request: NextRequest;
  err: unknown;
  other?: Record<string, any>;
}) {
  const { error, status } = handleApiError(err);
  const requestLogMessage = createRequestLogMessage(request, error);

  // Don't remove this console.error, it's used for debugging
  console.error(requestLogMessage.message, requestLogMessage.properties);

  return NextResponse.json(
    {
      status: 'error',
      code: error.code,
      message: error.message,
      ...(error?.isLimitReached && {
        isLimitReached: error.isLimitReached,
      }),
      ...(other || {}),
    },
    { status: status },
  );
}
