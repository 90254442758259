import { GPTModel } from '@/app/(dashboard)/aibot/[chatbotId]/(layout)/settings/types';
import {
  azureCanadaOpenAIApiInstanceName,
  azureCanadaOpenAIApiKey,
  azureCanadaOpenAIApiVersion,
  azureOpenAIApiInstanceName,
  azureOpenAIApiKey,
  azureOpenAIApiVersion,
  azureOpenAIEastUsApiInstanceName,
  azureOpenAIEastUsApiKey,
  azureVisionOpenAIApiInstanceName,
  azureVisionOpenAIApiKey,
  azureVisionOpenAIApiVersion,
} from '@/config/dashboard';

type GPTModelName = Extract<
  GPTModel,
  'gpt-4' | 'gpt-3.5-turbo' | 'gpt-4o' | 'gpt-4o-mini'
>;

export type AzureModelName =
  | GPTModelName
  | 'vision'
  | 'gpt35-summarize'
  | 'gpt-3.5-turbo-0125';

type AzureCredentials = {
  azureOpenAIApiInstanceName: string;
  azureOpenAIApiKey: string;
  azureOpenAIApiVersion: string;
  azureOpenAIApiDeploymentName: string;
};

const modelToAzureCredentials: Record<AzureModelName, AzureCredentials> = {
  'gpt-4': {
    azureOpenAIApiInstanceName,
    azureOpenAIApiKey,
    azureOpenAIApiVersion,
    azureOpenAIApiDeploymentName: 'livechatai-gpt4-32k',
  },
  'gpt-3.5-turbo': {
    azureOpenAIApiInstanceName,
    azureOpenAIApiKey,
    azureOpenAIApiVersion,
    azureOpenAIApiDeploymentName: 'livechatai_gpt35',
  },
  'gpt-4o': {
    azureOpenAIApiInstanceName: azureOpenAIEastUsApiInstanceName,
    azureOpenAIApiKey: azureOpenAIEastUsApiKey,
    azureOpenAIApiVersion,
    azureOpenAIApiDeploymentName: 'gpt-4o',
  },
  'gpt-4o-mini': {
    azureOpenAIApiInstanceName: azureOpenAIEastUsApiInstanceName,
    azureOpenAIApiKey: azureOpenAIEastUsApiKey,
    azureOpenAIApiVersion,
    azureOpenAIApiDeploymentName: 'livechatai-gpt-4o-mini',
  },
  'gpt-3.5-turbo-0125': {
    azureOpenAIApiInstanceName: azureCanadaOpenAIApiInstanceName,
    azureOpenAIApiKey: azureCanadaOpenAIApiKey,
    azureOpenAIApiVersion: azureCanadaOpenAIApiVersion,
    azureOpenAIApiDeploymentName: 'livechatai-0125-gpt35',
  },
  'gpt35-summarize': {
    azureOpenAIApiInstanceName,
    azureOpenAIApiKey,
    azureOpenAIApiVersion,
    azureOpenAIApiDeploymentName: 'summarize',
  },
  vision: {
    azureOpenAIApiInstanceName: azureVisionOpenAIApiInstanceName,
    azureOpenAIApiKey: azureVisionOpenAIApiKey,
    azureOpenAIApiVersion: azureVisionOpenAIApiVersion,
    azureOpenAIApiDeploymentName: 'vision-api',
  },
};

const getAzureCredentials = (modelName: AzureModelName): AzureCredentials => {
  switch (modelName) {
    case 'gpt-4':
      return modelToAzureCredentials['gpt-4'];
    case 'gpt-3.5-turbo':
      return modelToAzureCredentials['gpt-3.5-turbo'];
    case 'gpt-4o':
      return modelToAzureCredentials['gpt-4o'];
    case 'gpt-4o-mini':
      return modelToAzureCredentials['gpt-4o-mini'];
    case 'gpt-3.5-turbo-0125':
      return modelToAzureCredentials['gpt-3.5-turbo-0125'];
    case 'gpt35-summarize':
      return modelToAzureCredentials['gpt35-summarize'];
    case 'vision':
      return modelToAzureCredentials['vision'];
    default:
      return modelToAzureCredentials['gpt-3.5-turbo'];
  }
};

export default getAzureCredentials;
