import { BaseMessage } from '@langchain/core/messages';
import { TiktokenModel, encodingForModel } from 'js-tiktoken';

export const getMessageString = (message: BaseMessage): string => {
  switch (message._getType()) {
    case 'human':
      return `Human: ${message.content}`;
    case 'ai':
      return `Assistant: ${message.content}`;
    default:
      return `${message.content}`;
  }
};

export const serializeChatHistory = (chatHistory: Array<BaseMessage>): string =>
  chatHistory.map((chatMessage) => getMessageString(chatMessage)).join('\n');

export const serializeChatHistoryWithTokenLimit = ({
  chatHistory,
  tokenLimit,
  gptModel,
}: {
  chatHistory: Array<BaseMessage>;
  tokenLimit: number;
  gptModel: TiktokenModel;
}): string => {
  const gptTokenizer = encodingForModel(gptModel);

  let tokenCount = 0;
  const chatHistoryStrings: string[] = [];

  for (const chatMessage of chatHistory) {
    const messageString = getMessageString(chatMessage);
    const encodedMessage = gptTokenizer.encode(messageString);
    const messageTokenCount = encodedMessage.length;

    if (tokenCount + messageTokenCount > tokenLimit) {
      break;
    }

    tokenCount += messageTokenCount;
    chatHistoryStrings.push(messageString);
  }

  return chatHistoryStrings.reverse().join('\n');
};
