/**
 * Save data to local storage
 * @param {string} key Key of local storage
 * @param {string} value Is value of local storage.
 */
const set = (key: string, value: string): void => {
  localStorage.setItem(key, value);
};

/**
 * Get data from local storage
 * @param {string} key Key of local storage
 */
const get = (key: string): string | null => {
  return localStorage.getItem(key);
};

/**
 * Remove data from local storage
 * @param {string} key Key of local storage
 */
const remove = (key: string): void => {
  localStorage.removeItem(key);
};

const LocalStorage = { set, get, remove };

export default LocalStorage;

export const getLocalStorageValue = (key: string) => {
  try {
    const value = localStorage.getItem(key);

    return value;
  } catch (e) {
    return null;
  }
};

export const removeLocalStorageValue = (key: string) => {
  try {
    localStorage.remove(key);
  } catch (e) {}
};
