'use client';

import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';

import {
  EventStreamContentType,
  fetchEventSource,
} from '@microsoft/fetch-event-source';
import { ChatType, MessageType, OpenAIOperation, Sender } from '@prisma/client';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { produce } from 'immer';
import uniq from 'lodash/uniq';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import removeMd from 'remove-markdown';
import { create } from 'zustand';

import { SettingData } from '@/app/(dashboard)/aibot/[chatbotId]/(layout)/settings/types';
import createAgentUnreadChats from '@/app/actions/createAgentUnreadChats';
import { createChat } from '@/app/actions/createChat';
import { createContact } from '@/app/actions/createContact';
import { GetChatbotDetailReturnType } from '@/app/actions/getChatbotDetail';
import { getChatbotUserMessageCount } from '@/app/actions/getChatbotUserMessageCount';
import { getContact } from '@/app/actions/getContact';
import { insertResolutionLog } from '@/app/actions/insertResolutionLog';
import { insertTokenLog } from '@/app/actions/insertTokenLog';
import { requestHumanSupport } from '@/app/actions/requestHumanSupport';
import { saveContactData } from '@/app/actions/saveContactData';
import { sendEventToMake } from '@/app/actions/sendEventToMake';
import { updateChats } from '@/app/actions/updateChats';
import { updateContactSource } from '@/app/actions/updateContactSource';
import { SaveQuestionResponse } from '@/app/api/aibot/reply/route';
import { UserIntent } from '@/app/api/aibot/user-intent/route';
import isAppsumoUser from '@/app/api/appsumo/isAppsumoUser';
import { FollowUpQuestionSchema } from '@/app/schema/follow-up-question';
import deleteContactUnreadChat from '@/components/messenger/actions/deleteContactUnreadChat';
import type {
  Chat,
  Contact,
  Message,
  Reply,
} from '@/components/messenger/types';
import SuccessToast from '@/components/toasts/success-toast.component';
import WarningToast from '@/components/toasts/warning-toast.component';
import { INITIAL_TOKEN_USAGE, TokenUsage } from '@/constants/llm';
import { TranslationsFields } from '@/constants/translation';
import {
  EventStreamEndMessage,
  EventStreamErrorMessage,
  EventStreamEventData,
} from '@/helpers/eventStream';
import getCredit from '@/helpers/getCredit';
import { getParentLocation } from '@/helpers/getParentLocation';
import useContactChannelListener from '@/hooks/useContactChannelListener';
import useTranslationStore from '@/store/translation';
import { GTAGTracker } from '@/utils/gtag';
import LocalStorage from '@/utils/local-storage';
import { getMessageType } from '@/utils/message';
import { clearReply } from '@/utils/messenger';
import { safeJsonParse } from '@/utils/object';
import { sendClientSidePosthogEvent } from '@/utils/posthog';
import { getChatbotSettings } from '@/utils/settings';

dayjs.extend(isSameOrBefore);

export type AIFunctionAnimationStatus = 'idle' | 'run' | 'format';

export type Screen = 'list' | 'messenger';

type MessengerContextType = {
  chatType: ChatType;
  chatbot: GetChatbotDetailReturnType;
  aiAgentName: string;
  isFullHeight: boolean;
  fields: TranslationsFields;
  handleAskQuestion: (
    question: string,
    type: MessageType,
    isFollowUp?: boolean,
  ) => Promise<void>;
  handleSubmitUserData: (data: {
    email?: string;
    phone?: string;
    fullName?: string;
  }) => Promise<boolean>;
  handleGetContact: (contactId: string) => Promise<void>;
  updateActiveChat: (chatId: string | null) => void;
  updateConverationListView: ({
    view,
    scrollTop,
  }: {
    view: 'minimal' | 'banner' | 'dynamic';
    scrollTop?: number;
  }) => void;
};

export const MessengerContext = createContext<MessengerContextType>({
  chatbot: null,
  chatType: 'MESSENGER',
  aiAgentName: 'AI Agent',
  fields: null,
  isFullHeight: false,
  handleAskQuestion: async (question, type, isFollowUp) => {},
  handleSubmitUserData: async (data) => {
    return false;
  },
  handleGetContact: async (contactId: string) => {},
  updateActiveChat: (chatId: string | null) => {},
  updateConverationListView: ({ view, scrollTop }) => {},
});

type MessengerProviderProps = {
  children: ReactNode;
  chatbot: GetChatbotDetailReturnType;
  isFullHeight?: boolean;
  isPreviewPage?: boolean;
  chatId?: string;
  contactId?: string;
  chatType: ChatType;
  fields: TranslationsFields;
};

interface MessagesState {
  messages: Message[];
  updateMessages: (messages: Message[]) => void;
  addMessage: (message: Message) => void;
  updateMessage: (id: string, partial: Partial<Message>) => void;
  checkIsLastMessage: (id: string) => boolean;
  clearMessages(): void;
}
export const messagesStore = create<MessagesState>((set, get) => ({
  messages: [],
  updateMessages: (messages: Message[]) => set({ messages }),
  addMessage: (message: Message) =>
    set((state) => ({ messages: [...state.messages, message] })),
  updateMessage: (id: string, partial: Partial<Message>) =>
    set((state) => {
      const updatedData = produce(state.messages, (draft) => {
        const messageIndex = draft?.findIndex((message) => message.id === id);
        if (messageIndex === -1) return state.messages;

        draft[messageIndex] = {
          ...draft[messageIndex],
          ...partial,
        };
      });

      return { messages: updatedData };
    }),
  checkIsLastMessage: (id: string) => {
    const messages = get().messages;
    const lastMessage = messages[messages.length - 1];

    return lastMessage?.id === id;
  },
  clearMessages: () => set({ messages: [] }),
}));

interface MessengerState {
  contact: Contact;
  updateContact: (contact: Contact) => void;
  addMessageToContact: (chatId: string, message: SaveQuestionResponse) => void;
  activeChat: Chat;
  setActiveChat: (chat: Chat) => void;
  updateActiveChat: (partial: Partial<Chat>) => void;
  activeScreen: Screen;
  updateScreen: (screen: Screen) => void;
  localDataLoading: boolean;
  setLocalDataLoading: (value: boolean) => void;
  messageLoading: boolean;
  setMessageLoading: (value: boolean) => void;
  onlineAgents: { agentId: string; connectionId: string }[]; //agent ably connection id for online/offline status
  addOnlineAgent: (agentId: string, connectionId: string) => void;
  removeOnlineAgent: (agentId: string, connectionId: string) => void;
  resetOnlineAgents: () => void;
  aiFunctionLoadingStatus: AIFunctionAnimationStatus;
  setAiFunctionLoadingStatus: (status: AIFunctionAnimationStatus) => void;
  followUpQuestions: FollowUpQuestionSchema | null;
  addFollowUpQuestions: (questions: FollowUpQuestionSchema) => void;
  resetFollowUpQuestions: () => void;
  reset: () => void;
}

type MessengerStoreInitialState = Pick<
  MessengerState,
  | 'contact'
  | 'activeChat'
  | 'activeScreen'
  | 'localDataLoading'
  | 'messageLoading'
  | 'onlineAgents'
  | 'aiFunctionLoadingStatus'
  | 'followUpQuestions'
>;

const messengerStoreInitialState: MessengerStoreInitialState = {
  contact: null,
  activeChat: null,
  activeScreen: 'list',
  localDataLoading: true,
  messageLoading: false,
  onlineAgents: [],
  aiFunctionLoadingStatus: 'idle',
  followUpQuestions: null,
};

export const messengerStore = create<MessengerState>((set) => ({
  ...messengerStoreInitialState,
  updateContact: (contact: Contact) => set({ contact }),
  addMessageToContact: (chatId: string, message: SaveQuestionResponse) => {
    set((state) => {
      const updatedData = produce(state.contact, (draft) => {
        const chatIndex = draft?.chats?.findIndex((chat) => chat.id === chatId);
        if (chatIndex === -1) return;

        const chat = draft?.chats?.[chatIndex];
        chat?.messages?.unshift({
          ...message,
          isHumanSupportRequest: false,
        });

        draft?.chats?.splice(chatIndex, 1);
        draft?.chats?.unshift(chat);
      });

      return { contact: updatedData };
    });
  },
  setActiveChat: (activeChat: Chat) => set({ activeChat }),
  updateActiveChat: (partial: Partial<Chat>) =>
    set((state) => ({
      activeChat: {
        ...state.activeChat,
        ...partial,
      },
    })),
  updateScreen: (activeScreen: Screen) => set({ activeScreen }),
  setLocalDataLoading: (value: boolean) => set({ localDataLoading: value }),
  setMessageLoading: (value: boolean) => set({ messageLoading: value }),
  addOnlineAgent: (agentId: string, connectionId: string) =>
    set((state) => {
      const updatedData = produce(state.onlineAgents, (draft) => {
        draft?.push({ agentId, connectionId });
      });

      return { onlineAgents: uniq(updatedData) };
    }),
  removeOnlineAgent: (agentId: string, connectionId: string) =>
    set((state) => {
      const updatedData = produce(state.onlineAgents, (draft) => {
        const agentIndex = draft?.findIndex(
          (agent) =>
            agent.agentId === agentId && agent.connectionId === connectionId,
        );
        if (agentIndex === -1) return;

        draft?.splice(agentIndex, 1);
      });

      return { onlineAgents: updatedData };
    }),
  resetOnlineAgents: () => set({ onlineAgents: [] }),
  setAiFunctionLoadingStatus: (status: AIFunctionAnimationStatus) =>
    set({ aiFunctionLoadingStatus: status }),
  addFollowUpQuestions: (questions: FollowUpQuestionSchema) =>
    set({
      followUpQuestions: questions,
    }),
  resetFollowUpQuestions: () => {
    set({ followUpQuestions: null });
  },
  reset: () => {
    set(messengerStoreInitialState);
  },
}));

interface ReplyState {
  reply: Reply;
  clearReply: () => void;
  updateReply: (partial: Partial<Reply>) => void;
  addToReplyMessage: (message: string) => void;
}

export const replyStore = create<ReplyState>((set) => ({
  reply: { message: '', source: null },
  clearReply: () => set({ reply: { message: '', source: null } }),
  updateReply: (reply: Reply) =>
    set((state) => ({ reply: { ...state.reply, ...reply } })),
  addToReplyMessage: async (message: string) =>
    set((state) => ({
      reply: { ...state.reply, message: state.reply.message + message },
    })),
}));

const ignoredErrorMessagesParts = [
  "Failed to execute 'getReader' on 'ReadableStream'",
];

export const MessengerProvider: FC<MessengerProviderProps> = ({
  children,
  chatbot,
  fields,
  isFullHeight,
  isPreviewPage = false,
  chatId,
  contactId,
  chatType,
}) => {
  const chatbotPlan = chatbot?.workspace?.plan;
  const aiAgentName = (chatbot?.settings as SettingData)?.aiName || 'AI Agent';

  const queryClient = useQueryClient();

  const showNewMessageToast = (toastId: string, message: string) => {
    toast(
      <SuccessToast
        type="embed"
        title={useTranslationStore.getState().getValue('newMessageToast')}
        detail={message}
      />,
      {
        position: 'top-center',
        closeButton: false,
        toastId: toastId,
      },
    );
  };

  const updateNewMessageToast = (toastId: string, message: string) => {
    toast.update(toastId, {
      render: (
        <SuccessToast
          type="embed"
          title={useTranslationStore.getState().getValue('newMessageToast')}
          detail={message}
        />
      ),
      position: 'top-center',
      closeButton: false,
    });
  };

  const audioRef = useRef(null);

  const playMessageSound = () => {
    try {
      if (!audioRef.current) return;

      audioRef.current?.play();
    } catch {}
  };

  const handleMessageCallback = (
    chatId: string,
    message: SaveQuestionResponse,
  ) => {
    if (message?.sender !== 'AGENT') return;

    window.parent.postMessage({ key: 'message', value: message }, '*');

    if (messengerStore.getState().activeScreen === 'list') {
      const messageText =
        getMessageType(message.text, message.type) === MessageType.TEXT
          ? removeMd(message?.text || '')
          : 'Sent an image';

      //If toast is already active, update it
      if (toast.isActive(`messenger-new-message-${chatId}`)) {
        updateNewMessageToast(`messenger-new-message-${chatId}`, messageText);
      } else {
        //Else show new message toast
        showNewMessageToast(`messenger-new-message-${chatId}`, messageText);
      }

      playMessageSound();
    }

    const activeChat = messengerStore.getState().activeChat;
    if (activeChat?.id === chatId) {
      const messages = messagesStore.getState().messages;
      if (messages?.find((m) => m.id === message.id)) return;

      deleteContactUnreadChat(chatId, activeChat.contact.id).then();

      const humanMessage = { ...message, isHumanSupportRequest: false };
      messagesStore.getState().addMessage(humanMessage);
      messengerStore.getState().updateActiveChat({
        messages: [...activeChat?.messages, humanMessage],
        agent: message.agent,
      });
    }
    messengerStore.getState().addMessageToContact(chatId, message);
  };

  const handleEnterCallback = (
    connectionId: string,
    type: Sender,
    id: string,
  ) => {
    if (type === 'AGENT') {
      messengerStore.getState().addOnlineAgent(id, connectionId);
    }
  };

  const handleLeaveCallback = (
    connectionId: string,
    type: Sender,
    id: string,
  ) => {
    if (type === 'AGENT') {
      messengerStore.getState().removeOnlineAgent(id, connectionId);
    }
  };

  useContactChannelListener({
    handleMessageCallback,
    handleEnterCallback,
    handleLeaveCallback,
  });

  const setChatMessages = (chat: Chat) => {
    const oldMessages: Message[] =
      chat.messages && !!chat.messages
        ? chat.messages.map((message) => ({
            ...message,
          }))
        : [];

    oldMessages.sort(
      (a, b) => dayjs(a.createdAt).valueOf() - dayjs(b.createdAt).valueOf(),
    );

    messagesStore.getState().updateMessages([...oldMessages]);
  };

  const handleCreateNewChat = useCallback(async () => {
    let contactId = messengerStore.getState().contact?.id;
    if (!contactId) {
      const newContact = await createContact(chatbot.id);
      messengerStore.getState().updateContact(newContact);

      contactId = newContact.id;

      try {
        LocalStorage.set(`live-chat-ai-${chatbot.id}`, contactId);
      } catch (e) {}
    }

    const isAppsumoUserChatbot = isAppsumoUser(chatbotPlan?.id);
    const settings = getChatbotSettings(chatbot?.settings as SettingData);
    const modelName = settings.gptModel;
    const maxTokens = settings.maxTokens;
    const hasCustomOpenAIAPIAccess = chatbotPlan?.hasCustomOpenAIAPI;
    const openAIApiKey = hasCustomOpenAIAPIAccess
      ? chatbot?.openAIApiKey
      : null;
    const hasOpenAIApiKey =
      !!openAIApiKey && openAIApiKey !== '' && openAIApiKey.trim() !== '';

    const credit = getCredit({
      maxTokens,
      gptModel: modelName,
      isAppsumoUser: isAppsumoUserChatbot,
      hasOwnAPIKey: hasOpenAIApiKey,
    });

    const newChat = await createChat({
      workspaceId: chatbot.workspaceId,
      contactId,
      messageCredit: credit,
      chatType: chatType,
    });
    messengerStore.getState().setActiveChat(newChat);
    setChatMessages(newChat);

    return newChat;
  }, [
    chatbotPlan?.id,
    chatbotPlan?.hasCustomOpenAIAPI,
    chatbot?.settings,
    chatbot?.openAIApiKey,
    chatbot.workspaceId,
    chatbot.id,
    chatType,
  ]);

  const handleCheckUserIntent = useCallback(
    async (
      message: string,
    ): Promise<{ intent: UserIntent; tokenUsage: TokenUsage }> => {
      try {
        const hasCustomOpenAIAPIAccess = chatbotPlan?.hasCustomOpenAIAPI;
        const openAIApiKey = hasCustomOpenAIAPIAccess
          ? chatbot?.openAIApiKey
          : null;

        const intentResponse = await fetch('/api/aibot/user-intent', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            message,
            openAIApiKey,
          }),
        });
        const json = await intentResponse.json();

        if (json.status === 'error') {
          return {
            intent: UserIntent.NEUTRAL,
            tokenUsage: INITIAL_TOKEN_USAGE,
          };
        }

        return json.result;
      } catch (error) {
        return {
          intent: UserIntent.NEUTRAL,
          tokenUsage: INITIAL_TOKEN_USAGE,
        };
      }
    },
    [chatbot?.openAIApiKey, chatbotPlan?.hasCustomOpenAIAPI],
  );

  const handleGetFollowUpQuestions = useCallback(
    async ({
      message,
      chatId,
      chatbotId,
    }: {
      message: string;
      chatId: string;
      chatbotId: string;
    }): Promise<FollowUpQuestionSchema> => {
      try {
        const response = await fetch('/api/v1/aibot/follow-up', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            chatId,
            chatbotId,
            question: message,
          }),
        });

        if (!response.ok) {
          return null;
        }

        const json = await response.json();

        if (json.status === 'error') {
          return null;
        }

        return json?.question;
      } catch (error) {
        return null;
      }
    },
    [],
  );

  const handleAIHelped = useCallback(
    async (chatId: string) => {
      try {
        //When isIntentRoutingEnabled is true, we should update chat
        if (chatbot.isLiveChatEnabled && chatbot.isIntentRoutingEnabled) {
          await updateChats({ chatId: chatId, isAIHelped: true });

          messengerStore.getState().updateActiveChat({
            isAIHelped: true,
          });
        }

        await insertResolutionLog({
          chatId: chatId,
          isAIHelped: true,
        });
      } catch (e) {
        console.error(e);
      }
    },
    [chatbot.isIntentRoutingEnabled, chatbot.isLiveChatEnabled],
  );

  const handleAINotHelped = useCallback(
    async (chatId: string) => {
      try {
        //When isIntentRoutingEnabled is true, we should update chat
        if (chatbot.isLiveChatEnabled && chatbot.isIntentRoutingEnabled) {
          await updateChats({
            chatId: chatId,
            isAIHelped: false,
            //If the user clicks on the "Talk to an agent" button, the AI leaves the conversation and the agent takes over.
            isAgent: true,
          });

          const message = await requestHumanSupport({ chatId: chatId });
          await createAgentUnreadChats(chatId);

          messagesStore.getState().updateMessage(message.id, {
            isHumanSupportRequest: true,
          });
          messengerStore.getState().updateActiveChat({
            isAIHelped: false,
            isAgent: true,
          });
        }

        await insertResolutionLog({
          chatId: chatId,
          isAIHelped: false,
        });
      } catch (e) {
        console.error(e);
      }
    },
    [chatbot.isIntentRoutingEnabled, chatbot.isLiveChatEnabled],
  );

  const handleSendEvents = useCallback(
    async (chatbotId: string, question: string, answer: string) => {
      if (!isPreviewPage) return;

      const stateUserMessageCount =
        messagesStore
          .getState()
          .messages.filter((message) => message.sender === 'USER')?.length ?? 0;

      // check message count from the server
      const userMessageCount =
        stateUserMessageCount <= 1
          ? await getChatbotUserMessageCount(chatbotId)
          : stateUserMessageCount;

      if (userMessageCount <= 1) {
        GTAGTracker('app_first_message_sent', {
          ai_bot_id: chatbotId,
        });

        sendClientSidePosthogEvent('first_message_sent', {
          chatbotId: chatbotId,
          question: question,
          answer: answer,
        });
      }

      if (userMessageCount <= 3) {
        let event = null;
        switch (userMessageCount) {
          case 1:
            event = 'first_message_sent';
            break;
          case 2:
            event = 'second_message_sent';
            break;
          default:
            event = 'third_message_sent';
            break;
        }

        await sendEventToMake({
          eventType: event,
          value: `Question: ${question}.\n Answer: ${answer}`,
          chatbotId: chatbotId,
        });
      }
    },
    [isPreviewPage],
  );

  const handleAIResponse = useCallback(
    async ({
      chatId,
      chatbotId,
      question,
      type,
      isFollowUp = false,
    }: {
      chatId: string;
      chatbotId: string;
      question: string;
      type: MessageType;
      isFollowUp?: boolean;
    }) => {
      const controller = new AbortController();
      const animatedLetterCount = 2;
      let remainText = '';
      let messageId = null;
      let dataLinks = null;
      let animationId = null;

      // type animation function
      function animateResponseText() {
        if (controller.signal.aborted) {
          replyStore.getState().addToReplyMessage(remainText);
          remainText = '';
        }

        if (remainText.length > 0) {
          const fetchText = remainText.slice(0, animatedLetterCount);
          replyStore.getState().addToReplyMessage(fetchText);

          remainText = remainText.slice(animatedLetterCount);
        }

        animationId = requestAnimationFrame(animateResponseText);
      }

      // start animaion
      animateResponseText();

      await fetchEventSource('/api/v1/aibot/reply', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          chatbotId,
          chatId,
          question,
          messageType: type,
          isFollowUp,
          streaming: true,
        }),
        signal: controller.signal,
        openWhenHidden: true,
        async onopen(response) {
          //Everything is good
          if (
            response.ok &&
            response.headers.get('content-type') === EventStreamContentType
          ) {
            return;
          }

          //Rate limit error
          if (response.status === 429) {
            const message =
              (await response.json()).message || 'Too many requests';
            throw new Error(message);
          }

          //Internal server error
          if (response.status === 500) {
            const message =
              (await response.json()).message || 'Something went wrong';
            throw new Error(message);
          }

          //Unauthorized error
          if (!response.ok) {
            throw new Error(response.statusText);
          }

          // JSON response
          if (
            response.status === 200 &&
            response.headers.get('content-type') === 'application/json'
          ) {
            const { isAgent, newMessage } = await response.json();
            if (newMessage?.id) {
              messageId = newMessage.id;
            }
            if (isAgent) {
              controller.abort();
              // stop type animation
              cancelAnimationFrame(animationId);

              window.parent.postMessage(
                { key: 'animation', value: 'aiTypingEnd' },
                '*',
              );
              messengerStore.getState().setMessageLoading(false);
              messengerStore.getState().updateActiveChat({
                isAgent: true,
              });
              return;
            }
          }
        },
        async onmessage(message) {
          if (message.event === 'data' && message.data) {
            const data = safeJsonParse<EventStreamEventData>(message.data);

            if (data.type === 'chunk') {
              const _result = clearReply(remainText);
              remainText = _result + data.token;
            }

            if (data.type === 'action-status') {
              messengerStore.getState().setAiFunctionLoadingStatus(data.status);
            }
          }

          if (message.event === 'end' && message.data) {
            const data = safeJsonParse<EventStreamEndMessage>(message.data);

            messageId = data.id;
            dataLinks = data.links;

            if (data.debugMessages) {
              data.debugMessages.forEach((message) => {
                // eslint-disable-next-line no-console
                console.log(message);
              });
            }
          }

          if (message.event === 'error' && message.data) {
            const data = safeJsonParse<EventStreamErrorMessage>(message.data);

            throw new Error(data.message);
          }
        },
        onclose() {
          if (isPreviewPage) {
            queryClient.invalidateQueries({
              queryKey: [`chatbot_first-message_${chatbot.id}`],
            });
          }

          if (messengerStore.getState().activeChat?.isAgent) {
            messengerStore.getState().updateActiveChat({
              isAgent: false,
            });
          }

          window.parent.postMessage(
            { key: 'animation', value: 'aiTypingEnd' },
            '*',
          );
        },
        onerror(err) {
          const isIgnoredError = ignoredErrorMessagesParts.some((part) =>
            err?.message?.includes(part),
          );
          if (isIgnoredError) return;

          throw new Error(err);
        },
      });

      // Wait until remainText is empty before proceeding
      await new Promise<void>((resolve) => {
        const checkRemainText = setInterval(() => {
          if (remainText.length <= 0) {
            clearInterval(checkRemainText);
            resolve(); // Resolve once remainText is empty
          }
        }, 50); // Check every 100ms
      });

      // stop type animation
      cancelAnimationFrame(animationId);
      remainText = '';

      const responseMessage = replyStore.getState().reply.message;

      messagesStore.getState().addMessage({
        id: messageId,
        text: responseMessage,
        sender: 'AI',
        type: 'TEXT', // AI message type is always text
        source: dataLinks,
        agent: null,
        createdAt: dayjs().toDate(),
        isHumanSupportRequest: false,
      });

      messengerStore.getState().setMessageLoading(false);
      replyStore.getState().clearReply();

      await handleSendEvents(chatbot.id, question, responseMessage);

      return messageId;
    },
    [chatbot?.id, isPreviewPage, queryClient, handleSendEvents],
  );

  const handleAskQuestion = useCallback(
    async (question: string, type: MessageType, isFollowUp?: boolean) => {
      try {
        let chatId = messengerStore.getState().activeChat?.id;

        if (!chatId) {
          const chat = await handleCreateNewChat();
          chatId = chat?.id;
        }

        const lastMessageDate =
          messagesStore.getState().messages?.[
            messagesStore.getState().messages.length - 1
          ]?.createdAt;

        //if now greater than last message date, we should use this date, otherwise we should use last message date + 1 second
        const createdAt = dayjs().isSameOrBefore(lastMessageDate)
          ? dayjs(lastMessageDate).add(1, 'second').toDate()
          : dayjs().toDate();

        messagesStore.getState().addMessage({
          type,
          text: question,
          sender: 'USER',
          createdAt: createdAt,
          isHumanSupportRequest: false,
        });

        replyStore.getState().clearReply();
        messengerStore.getState().resetFollowUpQuestions();
        messengerStore.getState().setMessageLoading(true);
        window.parent.postMessage(
          { key: 'animation', value: 'aiTypingStart' },
          '*',
        );

        const isAgentChat = messengerStore.getState().activeChat?.isAgent;
        const settings = getChatbotSettings(chatbot?.settings as SettingData);
        const followUpQuestionEnabled = settings.followUpQuestionEnabled;

        let intent = UserIntent.NEUTRAL;
        let userIntentTokenUsage: TokenUsage = INITIAL_TOKEN_USAGE;

        const [messageId, intentResult, followUpQuestions] = await Promise.all([
          handleAIResponse({
            chatId,
            chatbotId: chatbot.id,
            question,
            type,
            isFollowUp,
          }),
          !isAgentChat ? handleCheckUserIntent(question) : null,
          followUpQuestionEnabled
            ? handleGetFollowUpQuestions({
                message: question,
                chatId,
                chatbotId: chatbot.id,
              })
            : null,
        ]);

        if (followUpQuestions && !!followUpQuestions?.length) {
          messengerStore.getState().addFollowUpQuestions(followUpQuestions);
        }

        //If active chat is not agent, we should check user intent
        if (!isAgentChat && intentResult) {
          intent = intentResult.intent;
          userIntentTokenUsage = intentResult.tokenUsage;
        }

        if (intent === UserIntent.AI_HELPED_THE_USER) {
          handleAIHelped(chatId);
        }

        if (intent === UserIntent.USER_WANTS_HUMAN_SUPPORT) {
          handleAINotHelped(chatId);
        }

        if (messageId && userIntentTokenUsage) {
          insertTokenLog({
            workspaceId: chatbot.workspaceId,
            chatbotId: chatbot.id,
            messageId,
            operation: OpenAIOperation.USERINTENT,
            tokenUsage: userIntentTokenUsage,
          });
        }
      } catch (error) {
        messengerStore.getState().setMessageLoading(false);
        window.parent.postMessage(
          { key: 'animation', value: 'aiTypingEnd' },
          '*',
        );

        toast(
          <WarningToast
            type="embed"
            detail={error?.message || 'Something went wrong'}
            dangerouslySetInnerHTML
          />,
          {
            position: 'top-center',
            closeButton: false,
          },
        );
      }
    },
    [
      chatbot?.id,
      chatbot?.settings,
      handleCreateNewChat,
      chatbot.workspaceId,
      handleAIHelped,
      handleAINotHelped,
      handleCheckUserIntent,
      handleAIResponse,
      handleGetFollowUpQuestions,
    ],
  );

  const handleSubmitUserData = async (data: {
    email?: string;
    phone?: string;
    fullName?: string;
  }) => {
    try {
      const contactId = messengerStore.getState().contact.id;

      const { status, contact, message } = await saveContactData({
        contactId,
        ...data,
      });

      if (status === 'error') {
        toast(
          <WarningToast
            type="embed"
            detail={useTranslationStore.getState().getValue('defaultError')}
          />,
          {
            position: 'top-center',
            closeButton: false,
          },
        );
        return false; // Hata durumunda false döndür
      }

      toast(
        <SuccessToast
          type="embed"
          detail={useTranslationStore.getState().getValue('userDataSuccess')}
        />,
        {
          position: 'top-center',
          closeButton: false,
        },
      );

      messengerStore.getState().updateContact({
        ...messengerStore.getState().contact,
        ...contact,
      });

      return true; // Başarılı durumda true döndür
    } catch (e) {
      toast(
        <WarningToast
          type="embed"
          detail={useTranslationStore.getState().getValue('defaultError')}
        />,
        {
          position: 'top-center',
          closeButton: false,
        },
      );
      return false; // Hata durumunda false döndür
    }
  };

  const handleGetContact = async (contactId: string) => {
    messengerStore.getState().setLocalDataLoading(true);

    const contact = await getContact(contactId);
    messengerStore.getState().updateContact(contact);
    if (!contact.chatSource) {
      const href = getParentLocation();
      updateContactSource(contact.id, href);
    }

    messengerStore.getState().setLocalDataLoading(false);
  };

  const updateActiveChat = (chatId: string | null) => {
    if (!chatId) {
      messengerStore.getState().setActiveChat(null);
      messagesStore.getState().clearMessages();
      return;
    }

    const contact = messengerStore.getState().contact;
    const contactChat = contact.chats?.find((chat) => chat.id === chatId);

    if (contactChat) {
      const chat = {
        ...contactChat,
        chatbotId: chatbot.id,
        chatType: chatType,
      };

      messengerStore.getState().setActiveChat(chat);
      setChatMessages(chat);
    }
  };
  const getTranslationAndUpdate = useCallback(async () => {
    if (!chatbot?.id || !chatbot?.language) return;

    if (!chatbot?.isTranslationEnabled) {
      useTranslationStore.getState().resetTranslationFields();
      return;
    }

    useTranslationStore
      .getState()
      .updateTranslationFields(fields, chatbot.language);
  }, [chatbot?.id, chatbot.language, chatbot?.isTranslationEnabled, fields]);

  const getActiveContactAndChat = useCallback(
    async (contactId: string, chatId: string) => {
      messengerStore.getState().setLocalDataLoading(true);

      const contact = await getContact(contactId);
      if (!contact) {
        messengerStore.getState().setLocalDataLoading(false);
        return;
      }

      messengerStore.getState().updateContact(contact);

      const contactChat = contact.chats?.find((chat) => chat.id === chatId);
      if (!contactChat) {
        messengerStore.getState().setLocalDataLoading(false);
        return;
      }

      const chat = {
        ...contactChat,
        chatbotId: chatbot.id,
        chatType: chatType,
      };

      messengerStore.getState().updateScreen('messenger');
      messengerStore.getState().setActiveChat(chat);
      setChatMessages(chat);

      messengerStore.getState().setLocalDataLoading(false);
    },
    [chatbot.id, chatType],
  );

  const activateMinimalView = () => {
    if (!document) return;
    const root = document.documentElement;
    if (!root) return;

    root.style.setProperty(
      '--wrapper-dynamic-grid-template-rows',
      '48px 1fr auto',
    );
    root.style.setProperty('--conversation-list-radius', '0px');
    root.style.setProperty('--header-height', '48px');
    root.style.setProperty('--header-min-height', 'auto');
    root.style.setProperty('--header-justify-content', 'space-between');
    root.style.setProperty('--header-agents-display', 'none');
  };

  const activateBannerView = () => {
    if (!document) return;
    const root = document.documentElement;
    if (!root) return;

    root.style.setProperty(
      '--wrapper-dynamic-grid-template-rows',
      '190px 1fr auto',
    );
    root.style.setProperty('--conversation-list-radius', '25px');
    root.style.setProperty('--header-height', 'auto');
    root.style.setProperty('--header-min-height', '198px');
    root.style.setProperty('--header-justify-content', 'center');
    root.style.setProperty('--header-agents-display', 'flex');
  };

  const activateDynamicView = (scrollTop: number) => {
    if (!document || !scrollTop) return;

    const root = document.documentElement;
    if (!root) return;

    const height = 198 - scrollTop / 3;

    root.style.setProperty(
      '--wrapper-dynamic-grid-template-rows',
      `${Math.max(height, 48)}px 1fr auto`,
    );
    root.style.setProperty(
      '--conversation-list-radius',
      `${24 - scrollTop / 3}px`,
    );
    root.style.setProperty('--header-height', `${height}px`);
    root.style.setProperty('--header-min-height', 'auto');
    root.style.setProperty('--header-justify-content', 'center');
    root.style.setProperty(
      '--header-agents-display',
      `${height < 160 ? 'none' : 'flex'}`,
    );
  };

  const updateConverationListView = ({
    view,
    scrollTop,
  }: {
    view: 'minimal' | 'banner' | 'dynamic';
    scrollTop?: number;
  }) => {
    switch (view) {
      case 'minimal':
        activateMinimalView();
        break;
      case 'banner':
        activateBannerView();
        break;
      case 'dynamic':
        activateDynamicView(scrollTop);
        break;
    }
  };
  useEffect(() => {
    getTranslationAndUpdate();

    return () => {
      useTranslationStore.getState().resetTranslationFields();
    };
  }, [getTranslationAndUpdate, chatbot?.id, chatbot?.language]);

  useEffect(() => {
    return () => {
      messengerStore.getState().reset();
      messagesStore.getState().clearMessages();
      replyStore.getState().clearReply();
    };
  }, []);

  useEffect(() => {
    if (!contactId || !chatId) return;

    getActiveContactAndChat(contactId, chatId);

    return () => {
      messengerStore.getState().updateContact(null);
      messengerStore.getState().setActiveChat(null);
      messagesStore.getState().clearMessages();
    };
  }, [getActiveContactAndChat, chatId, contactId]);

  return (
    <MessengerContext.Provider
      value={{
        chatbot,
        chatType,
        aiAgentName,
        isFullHeight,
        fields,
        handleAskQuestion,
        handleSubmitUserData,
        handleGetContact,
        updateActiveChat,
        updateConverationListView,
      }}
    >
      {children}

      <Tooltip
        id="messenger-tooltip"
        className="messenger-tooltip"
        classNameArrow="messenger-tooltip-arrow"
      />
      <audio ref={audioRef}>
        <source src="/assets/sounds/bell-notification.mp3" type="audio/mpeg" />
      </audio>
    </MessengerContext.Provider>
  );
};

export const useMessengerContext = () => useContext(MessengerContext);
