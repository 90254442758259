// used for updating for geting href of parent and updating for contact

export function getParentLocation() {
  try {
    const parentUrl = document.referrer;

    if (!parentUrl) {
      const parentHref = window.parent.location.href;
      return parentHref;
    }
    return parentUrl;
  } catch (error) {
    return null;
  }
}
