import {
  GPTModel,
  GPTModels,
} from '@/app/(dashboard)/aibot/[chatbotId]/(layout)/settings/types';

export const getOpenAIModelName = (model: GPTModel) => {
  if (model === GPTModels.GPT4) return 'gpt-4-1106-preview';
  if (model === GPTModels.GPT4o) return 'gpt-4o';

  return 'gpt-3.5-turbo';
};
