export const GTAGTracker = (name: string, args?: object) => {
  if (typeof window === 'undefined') return;
  if (!window.gtag) return;

  window.gtag('event', name, {
    event_category: 'web',
    event_label: name,
    ...(args || {}),
    stage: process?.env?.NEXT_PUBLIC_ENVIRONMENT,
  });
};
