import type { Links, Message, PublicLinks } from '@/components/messenger/types';
import { MessageType, Sender } from '@prisma/client';
import uniqBy from 'lodash/uniqBy';
import compact from 'lodash/compact';
import { z } from 'zod';

type SenderKeyValue = {
  [key in Sender]: string;
};
export const getMessageSenderName = ({
  message,
  target,
  aiAgentName,
}: {
  message: Message;
  target: 'USER' | 'AGENT';
  aiAgentName?: string;
}) => {
  if (!message || !message?.sender) return null;

  const sender: SenderKeyValue = {
    [Sender.AI]: aiAgentName || 'AI Agent',
    [Sender.USER]: target === 'AGENT' ? 'User' : 'You',
    [Sender.AGENT]: message.agent?.name || 'Agent',
  };

  return sender[message.sender];
};

export const distanceToRelevanceScore = (distance: number) => {
  const score = 1 - distance;

  return score.toFixed(2);
};

export const cleanAndDeduplicateLinks = (links: Links): PublicLinks => {
  if (!links) return [];

  const cleanLinks = links.map((item: Links[0]) => ({
    id: item?.libraryId,
    link: item.link,
    type: item.type,
    distance: item.distance,
  }));

  return compact(uniqBy(cleanLinks, 'id'));
};

export const cleanAndDeduplicatePublicLinks = (
  links: PublicLinks,
): PublicLinks => {
  if (!links) return [];

  const cleanLinks = links.map((item) => ({
    link: item.link,
    type: item.type,
    distance: item.distance,
  }));

  return compact(uniqBy(cleanLinks, 'link'));
};

const MD_IMAGE_REGEX = /^!\[.*?\]\(.*?\)/;
export const isMDImageAtTop = (message: string): boolean => {
  try {
    return MD_IMAGE_REGEX.test(message.trim());
  } catch {
    return false;
  }
};

export const getMessageType = (
  message: string,
  type: MessageType,
): MessageType => {
  const hasImageAtTop = isMDImageAtTop(message);

  return hasImageAtTop ? MessageType.IMAGE : type;
};
