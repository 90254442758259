export type TokenUsage = {
  promptTokens: number;
  completionTokens: number;
};

export const INITIAL_TOKEN_USAGE: TokenUsage = {
  promptTokens: 0,
  completionTokens: 0,
};

export const EMPTY_MESSAGE_RESPONSE =
  'Failed to generate a response. Please try again.';
