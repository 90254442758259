'use client';

import { DEFAULT_ENGLISH, TranslationsFields } from '@/constants/translation';

import { create } from 'zustand';

interface TranslationState {
  language: string;
  translationFields: TranslationsFields;
  updateTranslationFields: (fields: TranslationsFields, lang: string) => void;
  resetTranslationFields: () => void;
  getValue: (translationKey: keyof TranslationsFields) => string;
}

const useTranslationStore = create<TranslationState>(
  (set, get): TranslationState => ({
    language: "ENGLISH",
    translationFields: DEFAULT_ENGLISH,
    updateTranslationFields: (fields, lang) =>
      set({ translationFields: fields, language: lang }),
    resetTranslationFields: () =>
      set({ translationFields: DEFAULT_ENGLISH, language: "ENGLISH" }),
    getValue: (translationKey: keyof TranslationsFields) => {
      const fields = get().translationFields;
      return fields[translationKey];
    },
  }),
);

export default useTranslationStore;
