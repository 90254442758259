import { Sender } from '@prisma/client';
import Ably from 'ably/promises';
import { ErrorInfo } from 'react';

let ably: Ably.Realtime;
let channel: Ably.Types.RealtimeChannelPromise;

export type PresenceMessage = {
  connectionId: string;
  data: { type: Sender; id: string };
};

export const subscribeChannelPresence = (
  channelName: string,
  callbackOnEnter: (member: unknown) => void,
  callbackOnLeave: (member: unknown) => void,
) => {
  ably = new Ably.Realtime({
    authUrl: `/api/create-token?clientId=${channelName}`,
  });

  channel = ably.channels.get(channelName);

  channel.presence.subscribe('enter', function (member) {
    callbackOnEnter(member);
  });

  channel.presence.subscribe('leave', function (member) {
    callbackOnLeave(member);
  });

  channel.presence
    .get()
    .then((members?: Ably.Types.PresenceMessage[]) => {
      if (members && !!members.length) {
        members.forEach((member) => {
          callbackOnEnter(member);
        });
      }
    })
    .catch((err: ErrorInfo) => {
      console.error(err);
    });
};

export const unsubscribeChannelPresence = () => {
  channel?.presence?.unsubscribe();
};
