import {
  GPTModel,
  GPTModels,
} from '@/app/(dashboard)/aibot/[chatbotId]/(layout)/settings/types';

const getCredit = ({
  maxTokens,
  gptModel,
  isAppsumoUser,
  hasOwnAPIKey = false,
}: {
  maxTokens: number;
  gptModel: GPTModel;
  isAppsumoUser: boolean;
  hasOwnAPIKey?: boolean;
}) => {
  if (hasOwnAPIKey) {
    return 0;
  }

  if (isAppsumoUser) {
    const messageCount = Math.max(Math.ceil(maxTokens / 250) || 1, 1);
    const modelCredit =
      gptModel === GPTModels.GPT4 || gptModel === GPTModels.GPT4o ? 20 : 1;

    return messageCount * modelCredit;
  }

  return Math.max(Math.ceil(maxTokens / 250) || 1, 1);
};

export default getCredit;
