import { ContactChannelStatusData } from '@/app/api/aibot/reply/ably';
import { Sender } from '@prisma/client';
import Ably from 'ably/promises';

let ably: Ably.Realtime;
let channel: Ably.Types.RealtimeChannelPromise;

type MemberType = Exclude<Sender, 'AI'>;

type ConnectChannel = {
  channelName: string;
  member: { id: string; type: MemberType };
  callbackOnMessage: (data: unknown) => void;
  callbackOnChatStatus?: (data: ContactChannelStatusData) => void;
  isHistoryEnabled?: boolean;
};

export const connectChannel = ({
  channelName,
  member,
  callbackOnMessage,
  callbackOnChatStatus,
  isHistoryEnabled = false,
}: ConnectChannel) => {
  ably = new Ably.Realtime({
    authUrl: `/api/create-token?clientId=${channelName}`,
  });

  channel = ably.channels.get(
    `${isHistoryEnabled ? '[?rewind=30s]' : ''}${channelName}`,
  );

  channel.subscribe((message) => {
    if (message.name === 'message') callbackOnMessage(message.data);

    if (message.name === 'chat-status') callbackOnChatStatus?.(message.data);
  });

  channel.presence.enter(member);
};

export const disconnectChannel = () => {
  channel?.presence?.leave();
  channel?.unsubscribe();
  channel?.off();
  ably?.connection?.off();
};

export const sendMessage = (message: any) => {
  channel.publish({ name: 'message', data: message });
};
